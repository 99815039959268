body {
  margin-left:0px;
  margin-right:100px;
  margin-top:30px;
  font-family:sans-serif;
}

a { 
/*
  text-decoration:none;
*/
  color:black;
  
}

li a{
  color: white;
}

a.menu {
  text-decoration:none;
}

a.real {
  text-decoration:underline;
}

a:hover {
  text-decoration:underline;
}

div.menu {
  position: absolute;
  top: 0px;
  left: 0px;
}

div.content {
  position: absolute;
  top: 15px;
  left: 270px;
  width: 550px;
}

div.menu li.no-style {
  margin-top: 10px;
  list-style-type:none;
  background-color:#FFFFFF;
  border:none;
}

div.menu li {
  margin-top:0em;
  margin-bottom:0em;
  list-style-type:none;
  border-left:1px solid black;
  border-right:1px solid black;
  border-top:1px solid black;
  /*background-color:#EEEEEE;*/
  background-color:#0d5888;
  padding:1px;
  padding-left:10px;
  padding-right:1px;
  width:200px;
}

div.menu li.label {
  padding-left:2px;
  padding-right:9px;
  /*background-color:#CCCCCC;*/
  background-color: #508eb5;
  color: #fff;
}

div.menu li.last {
  border-bottom:1px solid black;
}

div.menu li.here {
list-style-type:none;
  border-left:1px solid black;
  border-right:1px solid black;
  border-top:1px solid black;
  padding-left:1px;
  padding-right:10px;
  /*background-color:#BBBBBB;*/
  background-color: #38627d;
}

